import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getSkill = createAsyncThunk(
  "skill/getSkill",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchMeta/getAllSkill`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addSkill = createAsyncThunk(
  "skill/addSkill",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/researchMeta/createSkill`, params)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateSkill = createAsyncThunk(
  "skill/updateSkill",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/researchMeta/editSkill/${params.id}`, {
        skill: params.skill,
      })
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteSkill = createAsyncThunk(
  "skill/deleteSkill",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/researchMeta/deleteSkill/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
