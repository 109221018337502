import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getResearchProjectsByOrg,
  changeResearchProjectStatus,
  getIndividualProject,
  getAllResearchMeta,
  runResearchProjectMatchingAlgo,
  getResearchProjectRespondents,
  getAllLiveResearchProjects,
  getScreenedQuestionForRespondent,
  getAllMeta
} from "./researchMetaThunk";


const initialState = {
  getResearchProjectsByOrgRes: { status: "", data: null },
  changeResearchProjectStatusRes: { status: "", data: null },
  getIndividualProjectRes: { status: "", data: null },
  getAllResearchMetaRes: { status: "", data: null },
  runResearchProjectMatchingAlgoRes: { status: "", data: null },
  getResearchProjectRespondentsRes: { status: "", data: null },
  getAllLiveResearchProjectsRes: { status: "", data: null },
  getScreenedQuestionForRespondentRes: {
    status: null,
    data: {},
  },
  getAllMetaRes: {
    status: null,
    data: {},
  },
};

export const researchProjectSlice = createSlice({
  name: "researchProject",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getResearchProjectsByOrg.pending.toString(), (state) => {
      return {
        ...state,
        getResearchProjectsByOrgRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getResearchProjectsByOrg.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getResearchProjectsByOrgRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getResearchProjectsByOrg.rejected.toString(), (state) => {
      return {
        ...state,
        getResearchProjectsByOrgRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(changeResearchProjectStatus.pending.toString(), (state) => {
      return {
        ...state,
        changeResearchProjectStatusRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      changeResearchProjectStatus.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          changeResearchProjectStatusRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(
      changeResearchProjectStatus.rejected.toString(),
      (state) => {
        return {
          ...state,
          changeResearchProjectStatusRes: {
            status: APIstatus.FAILURE,
            data: [],
          },
        };
      }
    );

    builder.addCase(getIndividualProject.pending.toString(), (state) => {
      return {
        ...state,
        getIndividualProjectRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getIndividualProject.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getIndividualProjectRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getIndividualProject.rejected.toString(), (state) => {
      return {
        ...state,
        getIndividualProjectRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getAllResearchMeta.pending.toString(), (state) => {
      return {
        ...state,
        getAllResearchMetaRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getAllResearchMeta.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getAllResearchMetaRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getAllResearchMeta.rejected.toString(), (state) => {
      return {
        ...state,
        getAllResearchMetaRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(
      runResearchProjectMatchingAlgo.pending.toString(),
      (state) => {
        return {
          ...state,
          runResearchProjectMatchingAlgoRes: {
            status: APIstatus.IN_PROGRESS,
            data: [],
          },
        };
      }
    );
    builder.addCase(
      runResearchProjectMatchingAlgo.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          runResearchProjectMatchingAlgoRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(
      runResearchProjectMatchingAlgo.rejected.toString(),
      (state) => {
        return {
          ...state,
          runResearchProjectMatchingAlgoRes: {
            status: APIstatus.FAILURE,
            data: [],
          },
        };
      }
    );

    builder.addCase(
      getResearchProjectRespondents.pending.toString(),
      (state) => {
        return {
          ...state,
          getResearchProjectRespondentsRes: {
            status: APIstatus.IN_PROGRESS,
            data: [],
          },
        };
      }
    );
    builder.addCase(
      getResearchProjectRespondents.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getResearchProjectRespondentsRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(
      getResearchProjectRespondents.rejected.toString(),
      (state) => {
        return {
          ...state,
          getResearchProjectRespondentsRes: {
            status: APIstatus.FAILURE,
            data: [],
          },
        };
      }
    );

    builder.addCase(getAllLiveResearchProjects.pending.toString(), (state) => {
      return {
        ...state,
        getAllLiveResearchProjectsRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getAllLiveResearchProjects.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getAllLiveResearchProjectsRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getAllLiveResearchProjects.rejected.toString(), (state) => {
      return {
        ...state,
        getAllLiveResearchProjectsRes: {
          status: APIstatus.FAILURE,
          data: [],
        },
      };
    })
      .addCase(
        getScreenedQuestionForRespondent.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getScreenedQuestionForRespondentRes: {
              status: APIstatus.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getScreenedQuestionForRespondent.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getScreenedQuestionForRespondentRes: {
              status: APIstatus.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        getScreenedQuestionForRespondent.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getScreenedQuestionForRespondentRes: {
              status: APIstatus.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(getAllMeta.pending.toString(), (state, action) => {
        return {
          ...state,
          getAllMetaRes: {
            status: APIstatus.IN_PROGRESS,
          },
        };
      })
      .addCase(getAllMeta.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          getAllMetaRes: {
            status: APIstatus.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getAllMeta.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          getAllMetaRes: {
            status: APIstatus.FAILURE,
            data: error?.message,
          },
        };
      })
  },
});
