import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getSeniorityLevel,
  addSeniorityLevel,
  updateSeniorityLevel,
  deleteSeniorityLevel,
} from "./seniorityLevelThunk";

const initialState = {
  getSeniorityLevelRes: { status: "", data: null },
  addSeniorityLevelRes: { status: "", data: null },
  updateSeniorityLevelRes: { status: "", data: null },
  deleteSeniorityLevelRes: { status: "", data: null },
};

export const seniorityLevelSlice = createSlice({
  name: "seniorityLevel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSeniorityLevel.pending.toString(), (state) => {
      return {
        ...state,
        getSeniorityLevelRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getSeniorityLevel.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getSeniorityLevelRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getSeniorityLevel.rejected.toString(), (state) => {
      return {
        ...state,
        getSeniorityLevelRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addSeniorityLevel.pending.toString(), (state) => {
      return {
        ...state,
        addSeniorityLevelRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addSeniorityLevel.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addSeniorityLevelRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(addSeniorityLevel.rejected.toString(), (state) => {
      return {
        ...state,
        addSeniorityLevelRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateSeniorityLevel.pending.toString(), (state) => {
      return {
        ...state,
        updateSeniorityLevelRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      updateSeniorityLevel.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateSeniorityLevelRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(updateSeniorityLevel.rejected.toString(), (state) => {
      return {
        ...state,
        updateSeniorityLevelRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
    builder.addCase(deleteSeniorityLevel.pending.toString(), (state) => {
      return {
        ...state,
        deleteSeniorityLevelRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      deleteSeniorityLevel.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          deleteSeniorityLevelRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(deleteSeniorityLevel.rejected.toString(), (state) => {
      return {
        ...state,
        deleteSeniorityLevelRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
