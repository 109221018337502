import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getCompanySize = createAsyncThunk(
  "companySize/getCompanySize",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchMeta/getAllCompanySize`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addCompanySize = createAsyncThunk(
  "companySize/addCompanySize",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/researchMeta/createCompanySize`, params)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateCompanySize = createAsyncThunk(
  "companySize/updateCompanySize",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/researchMeta/editCompanySize/${params.id}`, {
        companySize: params.companySize,
      })
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteCompanySize = createAsyncThunk(
  "companySize/deleteCompanySize",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/researchMeta/deleteCompanySize/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
