import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getEmploymentStatus = createAsyncThunk(
  "employmentStatus/getEmploymentStatus",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchMeta/getAllEmploymentStatus`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addEmploymentStatus = createAsyncThunk(
  "employmentStatus/addEmploymentStatus",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/researchMeta/createEmploymentStatus`, params)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateEmploymentStatus = createAsyncThunk(
  "employmentStatus/updateEmploymentStatus",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/researchMeta/editEmploymentStatus/${params.id}`, {
        employmentStatus: params.employmentStatus,
      })
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteEmploymentStatus = createAsyncThunk(
  "employmentStatus/deleteEmploymentStatus",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/researchMeta/deleteEmploymentStatus/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
