import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import { getSkill, addSkill, updateSkill, deleteSkill } from "./skillThunk";

const initialState = {
  getSkillRes: { status: "", data: null },
  addSkillRes: { status: "", data: null },
  updateSkillRes: { status: "", data: null },
  deleteSkillRes: { status: "", data: null },
};

export const skillSlice = createSlice({
  name: "skill",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSkill.pending.toString(), (state) => {
      return {
        ...state,
        getSkillRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getSkill.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getSkillRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getSkill.rejected.toString(), (state) => {
      return {
        ...state,
        getSkillRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addSkill.pending.toString(), (state) => {
      return {
        ...state,
        addSkillRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addSkill.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addSkillRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(addSkill.rejected.toString(), (state) => {
      return {
        ...state,
        addSkillRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateSkill.pending.toString(), (state) => {
      return {
        ...state,
        updateSkillRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateSkill.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateSkillRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(updateSkill.rejected.toString(), (state) => {
      return {
        ...state,
        updateSkillRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
    builder.addCase(deleteSkill.pending.toString(), (state) => {
      return {
        ...state,
        deleteSkillRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteSkill.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteSkillRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(deleteSkill.rejected.toString(), (state) => {
      return {
        ...state,
        deleteSkillRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
