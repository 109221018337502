import { combineReducers } from "redux";
import { themeSlice } from "./theme/themeSlice";
import { authSlice } from "./auth/authSlice";
import { userSlice } from "./user/userSlice";
import { organizationSlice } from "./organization/organizationSlice";
import { onboardingQuestionsSlice } from "./onboardingQuestions/onboardingQuestionsSlice";
import { criteriaQuestionSlice } from "./criteriaQuestions/criteriaQuestionsSlice";
import { mappingSlice } from "./mapping/mappingSlice";
import { contactsSlice } from "./contacts/contactsSlice";
import { runMatchAlgoSlice } from "./runMatchAlgo/runMatchAlgoSlice";
import { liveSurveySlice } from "./liveSurvey/liveSurveySlice";
import { surveyFeedbackSlice } from "./surveyFeedback/feedbackSlice";
import { surveySummarySlice } from "./surveySummary/surveySummarySlice";
import { respondentsSlice } from "./respondents/respondentsSlice";
import { blockedDomainsSlice } from "./blockedDomains/blockedDomainsSlice";
import { industrySlice } from "./industry/industrySlice";
import { employmentStatusSlice } from "./employmentStatus/employmentStatusSlice";
import { jobFunctionSlice } from "./jobFunction/jobFunctionSlice";
import { seniorityLevelSlice } from "./seniorityLevel/seniorityLevelSlice";
import { companySizeSlice } from "./companySize/companySizeSlice";
import { skillSlice } from "./skill/skillSlice";
import { jobTitleSlice } from "./jobTitle/jobTitleSlice";
import { researchProjectSlice } from "./researchMeta/researchMetaSlice";

const reducer = combineReducers({
  theme: themeSlice.reducer,
  auth: authSlice.reducer,
  users: userSlice.reducer,
  organization: organizationSlice.reducer,
  onboardingQuestions: onboardingQuestionsSlice.reducer,
  criteriaQuestions: criteriaQuestionSlice.reducer,
  mapping: mappingSlice.reducer,
  contacts: contactsSlice.reducer,
  algo: runMatchAlgoSlice.reducer,
  liveSurvey: liveSurveySlice.reducer,
  surveyFeedback: surveyFeedbackSlice.reducer,
  surveySummary: surveySummarySlice.reducer,
  respondents: respondentsSlice.reducer,
  blockedDomains: blockedDomainsSlice.reducer,
  industry: industrySlice.reducer,
  employmentStatus: employmentStatusSlice.reducer,
  jobFunction: jobFunctionSlice.reducer,
  seniorityLevel: seniorityLevelSlice.reducer,
  companySize: companySizeSlice.reducer,
  skill: skillSlice.reducer,
  jobTitle: jobTitleSlice.reducer,
  researchProject: researchProjectSlice.reducer,
});

export default reducer;
