import React, { Component } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Drawer, Menu, Button, Modal, Form, Input } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import { DIR_RTL } from "../../constants/ThemeConstant";
import { withRouter } from "react-router-dom";
import { changeAdminPassword } from "../../redux/toolkit/auth/authThunk";

class NavPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      passwordModalVisible: false,
    };

  }

  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  signOut = () => {
    localStorage.removeItem("authToken");
    this.props.history.push("/auth/login");
  };

  showPasswordModal = () => {
    this.setState({ passwordModalVisible: true });
  };

  handlePasswordModalCancel = () => {
    this.setState({ passwordModalVisible: false });
  };

  handlePasswordSubmit = (values) => {
    this.props.changeAdminPassword({
      oldPassword: values.currentPassword,
      newPassword: values.confirmNewPassword,
    });
    this.setState({ passwordModalVisible: false });
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
          <Menu.Item key="changePassword">
            <Button onClick={this.showPasswordModal}>
              Change Password
            </Button>
          </Menu.Item>
          <Menu.Item key="logout">
            <Button onClick={this.signOut}>
              Sign Out
            </Button>
          </Menu.Item>
          <Menu.Item key="panel" onClick={this.showDrawer}>
            <span>
              <SettingOutlined className="nav-icon mr-0" />
            </span>
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement={this.props.direction === DIR_RTL ? "left" : "right"}
          width={350}
          onClose={this.onClose}
          open={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
        <Modal
          title="Change Password"
          open={this.state.passwordModalVisible}
          onCancel={this.handlePasswordModalCancel}
          footer={null}
        >
          <Form layout="vertical" onFinish={this.handlePasswordSubmit}>
            <Form.Item
              label="Current Password"
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your current password!",
                },
              ]}
            >
              <Input.Password className="rounded" />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: "Please enter your new password!" },
              ]}
            >
              <Input.Password className="rounded" />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirmNewPassword"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password className="rounded" />
            </Form.Item>
            <Form.Item className="mb-0">
              <div className="text-right">
                <Button className="rounded" type="primary" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.theme;
  return { locale };
};

const mapDispatchToProps = {
  changeAdminPassword,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavPanel)
);
