import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getJobTitle = createAsyncThunk(
  "jobTitle/getJobTitle",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchMeta/getAllJobTitle`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addJobTitle = createAsyncThunk(
  "jobTitle/addJobTitle",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/researchMeta/createJobTitle`, params)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateJobTitle = createAsyncThunk(
  "jobTitle/updateJobTitle",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/researchMeta/editJobTitle/${params.id}`, {
        jobTitle: params.jobTitle,
      })
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteJobTitle = createAsyncThunk(
  "jobTitle/deleteJobTitle",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/researchMeta/deleteJobTitle/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
