import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getSeniorityLevel = createAsyncThunk(
  "seniorityLevel/getSeniorityLevel",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchMeta/getAllSeniorityLevel`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addSeniorityLevel = createAsyncThunk(
  "seniorityLevel/addSeniorityLevel",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/researchMeta/createSeniorityLevel`, params)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateSeniorityLevel = createAsyncThunk(
  "seniorityLevel/updateSeniorityLevel",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/researchMeta/editSeniorityLevel/${params.id}`, {
        seniorityLevel: params.seniorityLevel,
      })
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteSeniorityLevel = createAsyncThunk(
  "seniorityLevel/deleteSeniorityLevel",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/researchMeta/deleteSeniorityLevel/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
