import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getCriteriaQuestions,
  addCriteriaQuestion,
  editCriteriaQuestion,
  deleteCriteriaQuestion,
  getSingleCriteriaQuestion,
  getViewCriteria,
  updateCriteriaQuestionsOrder,
  getResearchProjectCriteria,
} from "./criteriaQuestionsThunk";

const initialState = {
  getCriteriaQuestionsRes: { status: "", data: null },
  addCriteriaQuestionRes: { status: "", data: null },
  deleteCriteriaQuestionRes: { status: "", data: null },
  editCriteriaQuestionRes: { status: "", data: null },
  getSingleCriteriaQuestionRes: { status: "", data: null },
  getViewCriteriaRes: { status: "", data: null },
  updateCriteriaQuestionsOrderRes: { status: "", data: null },
  getResearchProjectCriteriaRes: { status: "", data: null },
};

export const criteriaQuestionSlice = createSlice({
  name: "criteriaQuestions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCriteriaQuestions.pending.toString(), (state) => {
      return {
        ...state,
        getCriteriaQuestionsRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getCriteriaQuestions.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getCriteriaQuestionsRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getCriteriaQuestions.rejected.toString(), (state) => {
      return {
        ...state,
        getCriteriaQuestionsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getSingleCriteriaQuestion.pending.toString(), (state) => {
      return {
        ...state,
        getSingleCriteriaQuestionRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getSingleCriteriaQuestion.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getSingleCriteriaQuestionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getSingleCriteriaQuestion.rejected.toString(), (state) => {
      return {
        ...state,
        getSingleCriteriaQuestionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addCriteriaQuestion.pending.toString(), (state) => {
      return {
        ...state,
        addCriteriaQuestionRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      addCriteriaQuestion.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          addCriteriaQuestionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(addCriteriaQuestion.rejected.toString(), (state) => {
      return {
        ...state,
        addCriteriaQuestionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(editCriteriaQuestion.pending.toString(), (state) => {
      return {
        ...state,
        editCriteriaQuestionRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      editCriteriaQuestion.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          editCriteriaQuestionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(editCriteriaQuestion.rejected.toString(), (state) => {
      return {
        ...state,
        editCriteriaQuestionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteCriteriaQuestion.pending.toString(), (state) => {
      return {
        ...state,
        deleteCriteriaQuestionRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      deleteCriteriaQuestion.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          deleteCriteriaQuestionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(deleteCriteriaQuestion.rejected.toString(), (state) => {
      return {
        ...state,
        deleteCriteriaQuestionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getViewCriteria.pending.toString(), (state) => {
      return {
        ...state,
        getViewCriteriaRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getViewCriteria.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getViewCriteriaRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getViewCriteria.rejected.toString(), (state) => {
      return {
        ...state,
        getViewCriteriaRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(
      updateCriteriaQuestionsOrder.pending.toString(),
      (state) => {
        return {
          ...state,
          updateCriteriaQuestionsOrderRes: {
            status: APIstatus.IN_PROGRESS,
            data: [],
          },
        };
      }
    );
    builder.addCase(
      updateCriteriaQuestionsOrder.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateCriteriaQuestionsOrderRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(
      updateCriteriaQuestionsOrder.rejected.toString(),
      (state) => {
        return {
          ...state,
          updateCriteriaQuestionsOrderRes: {
            status: APIstatus.FAILURE,
            data: [],
          },
        };
      }
    );

    builder.addCase(getResearchProjectCriteria.pending.toString(), (state) => {
      return {
        ...state,
        getResearchProjectCriteriaRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getResearchProjectCriteria.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getResearchProjectCriteriaRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getResearchProjectCriteria.rejected.toString(), (state) => {
      return {
        ...state,
        getResearchProjectCriteriaRes: {
          status: APIstatus.FAILURE,
          data: [],
        },
      };
    });
  },
});
