import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getJobFunction = createAsyncThunk(
  "jobFunction/getJobFunction",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchMeta/getAllJobFunction`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addJobFunction = createAsyncThunk(
  "jobFunction/addJobFunction",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/researchMeta/createJobFunction`, params)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateJobFunction = createAsyncThunk(
  "jobFunction/updateJobFunction",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/researchMeta/editJobFunction/${params.id}`, {
        jobFunction: params.jobFunction,
      })
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteJobFunction = createAsyncThunk(
  "jobFunction/deleteJobFunction",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/researchMeta/deleteJobFunction/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
