import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getCompanySize,
  addCompanySize,
  updateCompanySize,
  deleteCompanySize,
} from "./companySizeThunk";

const initialState = {
  getCompanySizeRes: { status: "", data: null },
  addCompanySizeRes: { status: "", data: null },
  updateCompanySizeRes: { status: "", data: null },
  deleteCompanySizeRes: { status: "", data: null },
};

export const companySizeSlice = createSlice({
  name: "companySize",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanySize.pending.toString(), (state) => {
      return {
        ...state,
        getCompanySizeRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getCompanySize.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getCompanySizeRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getCompanySize.rejected.toString(), (state) => {
      return {
        ...state,
        getCompanySizeRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addCompanySize.pending.toString(), (state) => {
      return {
        ...state,
        addCompanySizeRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addCompanySize.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addCompanySizeRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(addCompanySize.rejected.toString(), (state) => {
      return {
        ...state,
        addCompanySizeRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateCompanySize.pending.toString(), (state) => {
      return {
        ...state,
        updateCompanySizeRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateCompanySize.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateCompanySizeRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(updateCompanySize.rejected.toString(), (state) => {
      return {
        ...state,
        updateCompanySizeRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
    builder.addCase(deleteCompanySize.pending.toString(), (state) => {
      return {
        ...state,
        deleteCompanySizeRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteCompanySize.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteCompanySizeRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(deleteCompanySize.rejected.toString(), (state) => {
      return {
        ...state,
        deleteCompanySizeRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
